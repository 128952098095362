import {useQuery} from "@apollo/client";
import {GET_AVAILABLE_QTY} from "graphql/MassBalance/Actions/AllocateSale";
import {getQtyandUom} from "modules/GlobalMassBalance/utils";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";

import {formatNumber, useUserSettings} from "providers/userSettings";

const ProductDetails = ({rowData, masterDetails}) => {
  const {
    register,
    formState: {errors},
    watch,
    setValue,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const watchTemplateType = watch("templateType");

  const productBaselineEmissionsList =
    masterDetails?.bioLcGetProductBaselineEmissionDetails
      ?.ProductBaselineEmissionDetails;
  const productList =
    masterDetails?.bioLcGetProductDetails?.ProductDetailsResponse;

  const watchUOM = watch("uom");
  const [availableQty, setAvailableQty] = useState("");

  const {quantity, quantity_uom} = getQtyandUom(rowData);

  const {data: availableQtyResponse, refetch} = useQuery(GET_AVAILABLE_QTY, {
    variables: {
      currentUom: "MT",
      quantity,
      targetUom: watchUOM ?? "MT",
    },
  });

  useEffect(() => {
    if (watchTemplateType === "ISCC Standard") {
      setValue("productType", "Raw material");
    }
  }, [watchTemplateType, setValue]);

  useEffect(() => {
    refetch({
      currentUom: "MT",
      quantity,
      targetUom: watchUOM ?? "MT",
    });
    setAvailableQty(
      availableQtyResponse?.bioLcMassBalanceGetQuantityUom?.convertedQuantity,
    );
  }, [watchUOM, refetch, quantity, availableQtyResponse]);

  useEffect(() => {
    if (rowData?.original?.outboundRecords?.status === "RESERVED") {
      setValue("uom", "MT");
      setValue("quantity", rowData?.original?.outboundRecords?.quantityOutMt);
    }
  }, [
    rowData?.original?.outboundRecords?.status,
    setValue,
    rowData?.original?.outboundRecords?.quantityOutMt,
  ]);

  return (
    <div className="grid sm:grid-cols-1 text-left md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-4 ">
      <FormGroup>
        <Label for="productType" className="fw-light">
          Type of product
        </Label>
        <Input
          type="select"
          id="productType"
          data-test="productType"
          invalid={!!errors.productType}
          {...computeProps("productType", {
            required: "Please select type of product",
          })}
        >
          {watchTemplateType === "ISCC Standard 2.6" ? (
            <option key="Raw material" value="Raw material">
              Raw material
            </option>
          ) : (
            <>
              <option key="none" value="">
                --Select an option--
              </option>
              {productList?.map((product) => (
                <option
                  key={product.material_long_name}
                  value={product.material_long_name}
                >
                  {product.material_long_name}
                </option>
              ))}
            </>
          )}
        </Input>

        {errors.productType && (
          <FormFeedback>{errors.productType.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="uom" className="fw-light">
          Unit of measure
        </Label>
        <Input
          type="select"
          id="uom"
          data-test="uom"
          invalid={!!errors.uom}
          {...computeProps("uom", {
            required: "Please select unit of measure",
          })}
          disabled={rowData?.original?.outboundRecords?.status === "RESERVED"}
        >
          <option value="MT">t (metric tons)</option>
          <option value="M3">meter cube</option>
        </Input>
        {errors.uom && <FormFeedback>{errors.uom.message}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="quantity" className="fw-light">
          Quantity
        </Label>
        <Input
          type="number"
          id="quantity"
          data-test="quantity"
          invalid={!!errors.quantity}
          {...computeProps("quantity", {
            required: "Please enter quantity",
          })}
          disabled={rowData?.original?.outboundRecords?.status === "RESERVED"}
        />

        {availableQty !== "" && watchUOM && (
          <Label className="text-sm font-light text-gray-600">{`You have ${formatNumber(
            parseFloat(availableQty ?? 0),
            decimalFormat,
            2,
          )} ${
            watchUOM === "M3" ? "meter cube" : "t (metric tons)"
          } available`}</Label>
        )}
        {errors.uom && <FormFeedback>{errors.uom.message}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="fossilFuelComparator" className="fw-light">
          Fossil fuel comparator
        </Label>

        <Input
          type="select"
          id="fossilFuelComparator"
          data-test="fossilFuelComparator"
          invalid={!!errors.fossilFuelComparator}
          {...computeProps("fossilFuelComparator", {
            required: "Please select fossil fuel comparator",
          })}
        >
          <option key="none" value="">
            --Select an option--
          </option>
          {productBaselineEmissionsList?.map((product) => (
            <option key={product.materialCode} value={product.materialCode}>
              {product.materialShortName}
            </option>
          ))}
        </Input>
        {errors.fossilFuelComparator && (
          <FormFeedback>{errors.fossilFuelComparator.message}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};

ProductDetails.propTypes = {
  masterDetails: PropTypes.object,
  rowData: PropTypes.object,
};

export default ProductDetails;
