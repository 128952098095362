import PropTypes from "prop-types";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

// delete version shown up on UI when user wants to delete an existing rule version.

const DeleteVersionModal = ({showDeleteModal, setDeleteModal, onDelete}) => {
  return (
    <Modal
      size="sm"
      isOpen={showDeleteModal}
      toggle={() => setDeleteModal(false)}
      className="modal-dialog-centered"
    >
      <ModalHeader className="flex justify-center items-center mt-4">
        <span className="text-lg text-black opacity-100 bg-transparent font-[400]">
          Delete version
        </span>
      </ModalHeader>
      <ModalBody className="text-center pt-0">
        <p className=" mb-0">Are you sure you want to delete this version?</p>
      </ModalBody>
      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 py-3 opacity-60 opacity-100-hover bg-transparent"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </Button>
          </div>
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 !py-[10px] opacity-80 opacity-100-hover bg-transparent"
              onClick={onDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

DeleteVersionModal.propTypes = {
  showDeleteModal: PropTypes.bool.isRequired,
  setDeleteModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteVersionModal;
