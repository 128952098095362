import {Bioverse as bioverseConfig} from "content/navigations";
import {useMemo} from "react";
import {uniqBy} from "lodash";
import {SectionType} from "modules/common";
import {useAppSetting} from "providers/appSetting";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {LOCATION_DETAILS} from "graphql/config-center/LocationDetails";
import capitalizeFirstLetter from "../../utils/helpers/stringHelpers";

const Dashboard = () => {
  const {appSetting, setAppSetting} = useAppSetting();
  const {data: locationData, loading: locationLoading} =
    useQuery(LOCATION_DETAILS);

  const uniqueLocations = uniqBy(
    locationData?.bioLcCoproConfigCenterLocationDetails?.Records ?? [],
    "location_code",
  );

  const bioverseMenu = useMemo(
    () =>
      bioverseConfig(
        appSetting?.currentCountry,
        uniqueLocations,
        locationLoading,
      ),
    [appSetting?.currentCountry, uniqueLocations, locationLoading],
  );

  const bioverseMenuItems = useMemo(() => {
    const userFeatures =
      appSetting?.currentCountryMappingData &&
      Object.entries(appSetting?.currentCountryMappingData)
        ?.filter(([, value]) => {
          return value === true;
        })
        ?.map((item) => item && item[0]);

    return (
      bioverseMenu &&
      bioverseMenu?.filter(
        (item) => item.isVisible && userFeatures?.includes(item?.module),
      )
    );
  }, [appSetting?.currentCountryMappingData, bioverseMenu]);

  const capitalizedcountryName = capitalizeFirstLetter(
    appSetting?.currentCountry,
  );

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"}, // Takes us back to 'Global' context
    {text: `${capitalizedcountryName}`, link: "/"},
  ];

  return (
    <>
      <Helmet>
        <title>BioVerse - Biofuels Value Chain</title>
      </Helmet>
      <div className="min-h-screen px-6 lg:px-10">
        {capitalizedcountryName !== "Global" && (
          <Breadcrumb className="px-8 pt-5  ">
            {breadcrumbItems.map((item, index) => (
              <BreadcrumbItem
                key={index}
                active={index === breadcrumbItems.length - 1}
              >
                {item.text === "BioVerse" ? (
                  <Link
                    to="/"
                    data-test="home-button"
                    onClick={() => {
                      const countryFeatureData =
                        appSetting?.featureMappingData?.find(
                          (item) => item.countryName === "GLOBAL",
                        );

                      setAppSetting({
                        ...appSetting,
                        currentCountry: "GLOBAL",
                        currentCountryMappingData: countryFeatureData,
                        currentModule: "",
                        currentModuleTitle: "",
                      });
                    }}
                  >
                    {item.text}
                  </Link>
                ) : index === breadcrumbItems.length - 1 ? (
                  <span>{item.text}</span>
                ) : (
                  <Link to={item.link}>{item.text}</Link>
                )}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        )}
        <main className="p-6 lg:px-10">
          <SectionType items={bioverseMenuItems} isDashboard />
        </main>
      </div>
    </>
  );
};

export default Dashboard;
