import {gql} from "@apollo/client";

export const COUNTRY_VARIATION_GET_QUERY = gql`
  query bioLcGetCountryVariationData {
    bioLcGetCountryVariationData {
      countryVariationData {
        countryName
        ukDftCountryCode
        plPolishCountryName
        esNationalSystemCode
      }
    }
  }
`;

export default {COUNTRY_VARIATION_GET_QUERY};
