import PropTypes from "prop-types";
import React from "react";

import {Down24} from "@bphxd/ds-core-react/lib/icons";
import GlobalSvg from "assets/images/countryFlags/global.png";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  Label,
  UncontrolledDropdown,
} from "reactstrap";
import "./index.scss";

const FormDropdownWithIcon = ({
  options,
  values,
  icons,
  label,
  hasAllOption,
  onChange,
  value,
  hookToBody,
  placeholder,
  invalid,
  errors,
  menuClassName,
}) => {
  const actualPlaceholder = placeholder || (hasAllOption ? "All" : "");
  const invalidClassName = invalid && "is-invalid form-control";
  const className = menuClassName + " rounded-0 bg-white";
  const hasValue = value && options[values.indexOf(value)];

  // Use black color for options and "All" option, and grey for placeholders
  const optionColor = hasValue || !placeholder ? "black" : "#b4b4b4";
  return (
    <>
      {label && <Label className="mb-2">{label}</Label>}
      <UncontrolledDropdown>
        <DropdownToggle
          color="standard-tertiary"
          className={`rounded-0 w-100 text-left bg-white form-dropdown-button ${invalidClassName}`}
        >
          <div
            className="d-flex mr-auto items-center"
            style={{color: optionColor}}
          >
            {hasValue ? (
              <>
                <img
                  className={`w-[28px] h-[19px] object-contain ${
                    options[values.indexOf(value)] === "global"
                      ? ""
                      : "border-[1px] border-solid border-[#f2f2f2]"
                  }`}
                  src={icons[values.indexOf(value)]}
                  alt={options[values.indexOf(value)]}
                />
                <span className="pl-3.5 text-nowrap capitalize">
                  {options[values.indexOf(value)]}
                </span>
              </>
            ) : (
              <>
                <img
                  className=" w-[28px] h-[19px] object-contain "
                  src={GlobalSvg}
                  alt="global"
                />
                <span className="pl-3.5 text-nowrap">{actualPlaceholder}</span>
              </>
            )}
          </div>
          <Down24 className="btn-icon-suffix" />
        </DropdownToggle>
        <DropdownMenu
          className={className}
          container={hookToBody ? "body" : ""}
        >
          {hasAllOption && (
            <DropdownItem onClick={() => onChange("")}>All</DropdownItem>
          )}
          {options?.map((option, index) => (
            <DropdownItem
              onClick={() => onChange(values[index])}
              key={`option-key-${option}`}
            >
              <img
                className={`w-[28px] h-[19px] object-contain ${
                  option === "global"
                    ? ""
                    : "border-[1px] border-solid border-[#f2f2f2]"
                }`}
                src={icons[index]}
                alt={option}
              />
              <span className="pl-3.5 capitalize">{option}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {errors && (
        <FormFeedback className="d-block"> {errors.message}</FormFeedback>
      )}
    </>
  );
};

FormDropdownWithIcon.propTypes = {
  options: PropTypes.array,
  values: PropTypes.array,
  icons: PropTypes.array,
  label: PropTypes.string,
  hasAllOption: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  hookToBody: PropTypes.bool,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  errors: PropTypes.object,
  menuClassName: PropTypes.string,
};
export default FormDropdownWithIcon;
